<template>
  <v-container
    fluid
    class="white-background main-listing-page"
    :class="[customClass]"
  >
    <slot name="body"></slot>
  </v-container>
</template>

<script>
export default {
  props: {
    customClass: {
      type: String,
      default: null,
    },
  },
};
</script>
